<template>
  <div>
    <loading v-if="Loading"/>
    <b-modal v-model="modalShow" title="News Dashboard" hide-footer size="lg">
      <validation-observer ref="news" tag="form" @submit.prevent="submitAdd">
        <b-form  @submit.prevent="submitAdd">
          <loading v-if="Loading"/>
          <b-row v-if="!Loading">

            <b-col cols="8">
              <b-form-group label="Judul">
                <validation-provider #default="{ errors }" name="title" rules="required">
                  <b-form-input v-model="formNews.title" name="title" placeholder="Title" locale="id" now-button :state="errors.length > 0 ? false : null"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group label="Status">
                <validation-provider #default="{ errors }" name="Status" rules="required">
                  <multiselect v-model="formNews.status" :options="statusOption" :multiple="false" :close-on-select="true" :clear-on-select="true" placeholder="Pilih Status" label="text" track-by="value"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Content">
                <validation-provider #default="{ errors }" name="Content" rules="required">
                  <quill-editor v-model="content" :options="editorOptions" style="height: 400px;"></quill-editor>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <br/>
          <br/>
          <br/>
          <b-button type="submit" variant="primary" v-if="!Loading">Submit</b-button>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-button v-if="!Loading" @click="showModal" size="sm" variant="primary">Tambah News</b-button> &nbsp;
    <br/>
    <br/>

    <!-- Tabel -->
    <b-row v-if="!Loading">
      <b-col class="col-12">
        <b-table striped hover :items="tableData" :fields="fields" :per-page="perPage" :current-page="currentPage">
          <template v-slot:cell(action)="row">
            <b-button @click="editNews(row.item)" variant="primary" size="sm">Edit</b-button>&nbsp;
            <b-button @click="deleteNews(row.item)" variant="danger" size="sm">Hapus</b-button>&nbsp;
          </template>
        </b-table>
        <div class="d-flex justify-content-end">
          <b-pagination v-model="currentPage" :total-rows="tableData.length" :per-page="perPage"></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from 'vue-multiselect';
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect,
    axios,
    quillEditor
  },
  data() {
    return {
      Loading: false,
      perPage: 10, // Jumlah item per halaman
      currentPage: 1, // Halaman saat ini
      fields: [
        {key:"title",text:"Title"},
        {key:"status",text:"Status"},
        {key:"action",text:"Action"},
      ],
      statusOption: [
        { text: 'Tampil', value: 1 },
        { text: 'Tidak Tampil', value: 0 }
      ],
      modalShow: false,
      formNews: _interfaces.master_news,
      tableData: [],
      dataWarga: [],
      content: '',
      isEdit: false,
      editorOptions: {
        modules: {
          toolbar: [
            [{ 'list': 'bullet' }, { 'list': 'ordered' }], // Untuk daftar tak berurut dan berurut
            ['bold', 'italic'] // Untuk bold dan italic
          ]
        }
      }
    };
  },
  methods: {
    init(){
      this.getData();
    },
    showModal() {
      this.isEdit = false;
      this.formNews.title = null
      this.content = null
      this.modalShow = true;
    },
    getData() {
      axios.post("idp/master/news/list",{id:null}).then((response) => {
        this.tableData = response.data.data
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
        this.modalShow = false;
      });
    },
    submitAdd() {
      this.$refs.news.validate().then((success) => {
        if(success){ 
          this.Loading = true;
          this.formNews.content = this.content;
          
          setTimeout(() => {

            if(this.isEdit == false){
              axios.post("idp/master/news/add",this.formNews).then((response) => {
                
              }).catch((error) => {
                if (error.status === 4000) {
                  localStorage.clear();
                  this.$router.push({ path: "/login" });
                  ({
                    title: "Login Expired",
                    message: "Sesi Login Habis",
                  });
                }
              }).finally(() => {
                this.Loading = false;
                this.modalShow = false;
                this.init();
              });
            }else{
              axios.post("idp/master/news/edit",this.formNews).then((response) => {
                
              }).catch((error) => {
                if (error.status === 4000) {
                  localStorage.clear();
                  this.$router.push({ path: "/login" });
                  ({
                    title: "Login Expired",
                    message: "Sesi Login Habis",
                  });
                }
              }).finally(() => {
                this.Loading = false;
                this.modalShow = false;
                this.init();
              });
            }
          }, 1000);

        }
      });
    },
    editNews(row){
      this.isEdit = true;
      this.modalShow = true;
      this.formNews.id = row.id
      this.formNews.title = row.title
      this.content = row.content
    },
    deleteNews(row){
      this.formNews.id = row.id
      this.$swal({
        title: "Hapus News?",
        icon: "question",
        background: "black",
        showCancelButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ea5455",
        confirmButtonText: "Save",
      }).then((result) => {
        if (result.isConfirmed) {  
          this.Loading = true;
          setTimeout(() => {
            axios.post("idp/master/news/delete",this.formNews).then((response) => {
              this.$swal({
                title: "Sukses",
                icon: "success",
                background: "black",
                showCancelButton: false,
                confirmButtonColor: "#7367f0",
                cancelButtonColor: "#ea5455",
                confirmButtonText: "Save",
              });
            }).catch((error) => {
              if (error.status === 4000) {
                localStorage.clear();
                this.$router.push({ path: "/login" });
                ({
                  title: "Login Expired",
                  message: "Sesi Login Habis",
                });
              }
            }).finally(() => {
              this.Loading = false;
              this.modalAkses = false;
              this.init();
            });
          }, 1000);
        } else {
          this.modalAkses = false
        }
      });
    }
  },
  mounted(){
    this.init();
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>